footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 20px 20px 0 20px;
    background-color: #263859;
    color: #fff;
    border-top: 2px solid #ff6768;
    z-index: 0;
}

footer img {
    height: 150px;
}

footer h2 {
    text-transform: uppercase;
    border-bottom: 1px solid #ff6768;
    width: fit-content;
    text-align: center;
}

footer .links ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100px;
    margin: 0;
    padding: 0;
}

footer .links ul li {
    list-style: none;
    margin-bottom: 5px;
}

footer .links ul li a {
    text-decoration: none;
    color: #fff;
    transition: color 0.2s linear;
}

footer .links ul li a:hover {
    color: #ff6768;
}

footer .links ul li:before {
    content: "\00BB \0020";
    color: #ff6768;
    margin-right: 5px;
}

footer button {
    border: none;
    width: 150px;
    height: 150px;
    text-transform: uppercase;
    transition: all 0.4s linear;
    background-color: transparent;
    color: #fff;
    font-size: 1.1rem;
}

footer button:hover {
    background-color: #ff6768;
    color: #263859;
    font-size: 1rem;
}

footer button:focus {
    outline: none;
}

footer .credits {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

footer .credits h5 {
    margin: 5px 0;
    width: fit-content;
}

footer .credits a {
    color: #fff;
    text-decoration: none;
    transition: all 0.2s linear;
    padding: 0 10px;
}

footer .credits a:hover {
    color: #211F1F;
}

@media screen and (max-width: 1024px) {
    footer {
        flex-wrap: wrap;
    }

    footer button {
        width: 100%;
        height: 50px;
        margin-top: 50px;
        border : 1px solid #ff6768;
    }

}

@media screen and (max-width: 768px) {

    footer img {
        width: 100%;
        object-fit: contain;
    }

}

@media screen and (max-width: 480px) {
    footer {
        flex-direction: column;
        align-items: center;
    }

    footer h2 {
        margin: 30px 0 10px 0;
        text-align: center;
    }

    footer .links{
        display: flex;
        flex-direction: column;
    }

    footer .links ul {
        justify-content: center;
    }

}
