header.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    transition: all 0.6s linear;
    height: 60px;
    z-index: 1000000;
    padding: 0 20px;
    background-color: #263859;
    border-bottom: 2px solid #6b778d;
}

.navbar .logo {
    position: relative;
    height: 45px;
    transition: all 0.6s linear;
}

.navbar .logo-container{

}

.navbar .heading {
    position: relative;
    color: #ccc;
    font-size: 1rem;
    display: none;
    transition: all 0.6s linear;
}

.navbar .navLinks {

}

.navbar .navLinks ul {
    display: flex;
    align-items: center;
    list-style: none;
    transition: all 0.6s linear;
}

.navbar .navLinks ul li{
    /*background-color: #263859;*/
}

.navbar a {
    margin: 0 8px;
    color: #fff;
    text-decoration: none;
}

.navbar a:hover {
    transition: all 0.6s linear 0.2s;
    color: #ff6768
}

.navbar button {
    display: none;
    background-color: transparent;
    border: none;
    margin: 0 5%;
}


.navbar .menu {
    color: #ccc;
}

@media screen and (max-width: 1024px) {
    header.navbar{
        background: #263859 !important;
        border: none !important;
    }

    .navbar .navLinks {
        display: none;
    }


    .navbar button {
        display: block;
    }

    .navbar .mobileMenu {
        display: block;
        height: auto;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        transition: all 0.6s linear;
        margin-top: 49px !important;
    }

    .navbar .mobileMenu ul {
        width: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        list-style: none;
        text-align: center;
        background: #263859 !important;
    }

    .navbar .mobileMenu ul li{
        margin: 5px 0;
    }
}

@media screen and (max-width: 768px) {
    .navbar .mobileMenu {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {

    .navbar .mobileMenu {
        width: 100%;
    }

    .navbar .heading {
        display: inline-block;
    }


    .navbar .mobileMenu ul {
        width: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        list-style: none;
        text-align: left;
    }
}
